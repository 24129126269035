import "./News.css";
import { useState, useEffect } from "react";
import sanityClient from "../../client";
import NewsCard from "../../components/NewsCard/NewsCard";
import SearchNews from "../../components/SearchNews/SearchNews";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

export default function News() {
  const [news, setNews] = useState(null);
  const [display, setDisplay] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState(0);


  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "news"]{
      title,
      slug,
      affiliate[]->{name,location},
      press_release,
      publishedDate,
      body,
      link
    }`
      )
      .then((data) => {
        setNews(
          data.sort(
            (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
          )
        );
        setDisplay(
          data.sort(
            (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
          )
        );
      })
      .catch(console.error);
  }, []);

  const newsPerPage = 6;
  const pagesVisited = pageNumber * newsPerPage;
  const pageCount = Math.ceil(display.length / newsPerPage);
  const changePage = ({ selected }) => {
    window.scrollTo(0, 0);
    setPageNumber(selected);
  };

  return (
    <div className="max-w-6xl w-full mx-auto px-4 py-10">
      <header>
        <h1 className="font-bold text-3xl">News & Press Releases</h1>
      </header>
      <SearchNews
        setDisplay={setDisplay}
        news={news}
        setPageNumber={setPageNumber}
        setSearch={setSearch}
      />
      <main>
        <div className="flex flex-wrap gap-4 justify-center">
          { pageNumber === 0 && search === 0 ? (
            <section className="newsCard">
              <Link to="/news/press-release">
                <div className="news-card">
                  <header className="bg-aajc-yellowWhite rounded-lg p-3">
                    <ul className="flex flex-wrap gap-2">
                      <li className="uppercase bg-aajc-darkgrey text-white text-xs font-bold px-2 py-1 rounded-lg">Washington</li>
                      <li className="uppercase bg-aajc-lightorange text-aajc-darkgrey text-xs font-bold px-2 py-1 rounded-lg">Press Release</li>
                    </ul>
                    <h1 className="pt-2 text-lg font-bold">Asian Americans Advancing Justice Denounces <br /> Supreme Court Decision on Affirmative Action</h1>
                  </header>
                  <article>
                    {/* <p className="text-xs py-2 font-bold">m d y</p> */}
                    <p className="text-sm">Washington, D.C. – June 29, 2023 – In response to the Supreme Court’s decision today in SFFA v. Harvard and SFFA v. UNC striking down the race-conscious admissions policies at both schools...</p>
                  </article>
                </div>
              </Link>
            </section>
          ) : ('') }
          
          {display && display.length > 0 ? (
            display
              .slice(pagesVisited, pagesVisited + newsPerPage)
              .map((item, key) => (
                <section className="newsCard" key={key}>
                  <NewsCard news={item} />
                </section>
              ))
          ) : (
            <h1>No news to be displayed</h1>
          )}
        </div>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBtns"}
          previousLinkClassName={"previousBtn"}
          nextLinkClassName={"nextBtn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActiveBtn"}
        />
      </main>
    </div>
  );
}
